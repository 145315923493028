<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-06-21 15:37:42
 * @LastEditTime: 2022-01-20 11:13:38
 * @Description: 配置报表
 * @Param:
 *         TableCode： 报表地址，替换参数TenantCode
 * @FilePath: \JT_Web_test\src\views\reportManage\configurationReport\index.vue
 -->


<template>
    <div class="configurationReport">
        <iframe :src="reportUrl" frameborder="0"></iframe>
    </div>
</template>

<script>
export default {
    // 配置报表
    name: 'configuration-report',
    components: {},
    props: {},
    data() {
        return {
            reportUrl: '',
        };
    },
    computed: {},
    watch: {
        $route() {
            this.splicingUrl();
        },
    },
    created() {
        this.splicingUrl();
    },
    mounted() {},
    destroyed() {},
    methods: {
        splicingUrl() {
            this.reportUrl = '';
            if (this.$route.meta.TableCode) {
                const url = this.$route.meta.TableCode;
                const tenantcode = this.$takeTokenParameters('TenantCode');
                // const reg = /(\{[^}]*\})/g;
                // const newUrl = url.replace(reg, tenantcode);
                this.reportUrl = `http://47.94.227.103:8075/WebReport/ReportServer?${url}&tenantcode=${tenantcode}`;
            }
        },
    },
};
</script>
<style lang="stylus" scoped>
.configurationReport
    iframe
        width 100%
        height 100%
</style>